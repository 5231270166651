<template>
  <svg
    id="Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76">
    <rect
      :fill="color"
      x="10.13"
      y="10.13"
      width="55.74"
      height="55.74"
      rx="27.87"
      ry="27.87"/>
    <path
      :fill="figure_color"
      d="m34.42,49.45c-1.28-.06-2.45-.44-3.4-1.34-2.37-2.28-4.74-4.57-7.09-6.87-1.05-1.03-1.05-2.61-.07-3.63.99-1.03,2.58-1.04,3.67,0,2.13,2.05,4.26,4.11,6.37,6.19.38.38.6.37.98,0,4.56-4.51,9.13-8.99,13.69-13.49.5-.49,1.04-.88,1.76-.93,1.14-.09,2.1.46,2.57,1.45.46.99.28,2.09-.53,2.91-1.7,1.71-3.43,3.4-5.14,5.1-3.04,3-6.08,5.98-9.11,8.99-1.03,1.03-2.25,1.57-3.7,1.63Z"/>
  </svg>
</template>

<script>
export default {
  props:{
    color:{
      type: String,
      default: "#39b54a"
    },
    figure_color:{
      type: String,
      default: "#fff"
    }
  }
}
</script>

<style lang="css">
</style>
