<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
  <g id="dashboard-icon-off" transform="translate(-120 -300)">
    <g id="Rectángulo_18" data-name="Rectángulo 18" transform="translate(120 300)" :fill="colorx" stroke="#707070" stroke-width="1" opacity="0">
      <rect width="36" height="36" stroke="none"/>
      <rect x="0.5" y="0.5" width="36" height="36" fill="none"/>
    </g>
    <path id="icono-locaciones" d="M638.632,403.466a10.55,10.55,0,0,0-10.5,10.578c0,8.285,9.513,16.841,9.918,17.2a.878.878,0,0,0,1.167,0c.4-.36,9.914-8.916,9.914-17.2A10.55,10.55,0,0,0,638.632,403.466Zm0,16.333a5.833,5.833,0,1,1,5.833-5.833A5.833,5.833,0,0,1,638.632,419.8Z" transform="translate(-501.632 -100.466)" fill="none" :stroke="colorx" stroke-width="2"/>
  </g>
</svg>
</template>

<script>
export default {
  data: () => ({

  }),
  props: {
    colorx: {
      type: String,
      default: '#89899B'
    }
  }
}
</script>

<style lang="css">
</style>
