<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76">
    <path
      :fill="color"
      d="m34.22,30.64c0-4.56,0-9.12,0-13.68,0-.54.03-1.09.14-1.61.34-1.74,1.91-3.02,3.66-3,1.76.01,3.3,1.28,3.62,3.04.09.5.13,1.02.13,1.53,0,9.2,0,18.41,0,27.61,0,.51-.04,1.03-.13,1.53-.34,1.77-1.84,2.96-3.66,2.96-1.81,0-3.29-1.21-3.63-3-.09-.5-.13-1.02-.13-1.53,0-4.62,0-9.23,0-13.85Z"/>
    <path
      :fill="color"
      d="m41.75,59.86c.03,2.02-1.69,3.78-3.73,3.79-2.03.02-3.77-1.72-3.77-3.76,0-2.01,1.67-3.7,3.69-3.75,2.02-.05,3.77,1.67,3.81,3.72Z"/>
  </svg>
</template>

<script>
export default {
  props:{
    color:{
      type: String,
      default: "#c1272d"
    }
  }
}
</script>
