
import Upload from '@/components/shared/Upload.vue'
import DeliveryForm from "@/components/views/forms/DeliveryForm.vue";
import Success from "@/components/views/Success.vue";
import Denied from "@/components/views/Denied.vue";

export default [
  // {path: '/', component: Upload, name: 'root'},
  {path: '/', component: DeliveryForm, name: 'root'},
  {path: '/success', component: Success, name: 'root'},
  {path: '/denied', component: Denied, name: 'root'},
]
