<template>
  <svg
    id="Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76">
    <path
      :fill="color" d="m43.27,49.25h-10.53s-.04-.02-.06-.02c-2.45-.42-4.06-2.32-4.07-4.8,0-.9,0-1.8,0-2.7,0-1.05-.06-2.09.03-3.13.15-1.69.99-2.95,2.47-3.78.23-.13.32-.26.31-.52-.02-.77-.02-1.55.14-2.31.79-3.59,4.21-5.81,7.81-5.08,2.68.55,4.8,2.84,5.14,5.53.08.66.07,1.33.07,1.99,0,.16.05.25.19.33,1.7.91,2.57,2.34,2.61,4.26.03,1.84.02,3.69,0,5.53-.02,1.55-.64,2.82-1.86,3.78-.67.52-1.43.8-2.26.94Zm-5.28-15.01c.5,0,1.01,0,1.51,0,.96,0,1.92,0,2.87,0,.14,0,.27,0,.27-.19,0-.4.03-.81-.02-1.21-.28-2.59-2.6-4.44-5.18-4.11-1.91.24-3.18,1.33-3.86,3.12-.25.66-.25,1.36-.24,2.06,0,.33,0,.33.33.33,1.43,0,2.87,0,4.3,0Zm1.66,8.29c0-.43,0-.86,0-1.3,0-.75-.56-1.31-1.27-1.31-.72,0-1.27.55-1.28,1.3,0,.86,0,1.73,0,2.59,0,.74.58,1.32,1.28,1.31.7-.01,1.25-.57,1.26-1.3,0-.43,0-.86,0-1.3Z"/>
  </svg>
</template>

<script>
export default {
  props:{
    color:{
      type: String,
      default: "#b3b3b3"
    }
  }
}
</script>

<style lang="css">
</style>
