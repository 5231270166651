<template>
  <article class="form_modal__fair delivery_form">
    <transition name="fade">
      <section
        class="delivery_form__loaders"
        v-if="loader">
        <spin-loader></spin-loader>
        <percentage-loader :percentage="loading_percentage" ></percentage-loader>
      </section>
    </transition>
    <alert-modal :description="alert_message" @closeAlertModal="closeAlertModal()" v-if="show_alert"></alert-modal>

    <section class="delivery_form__head">
      <fuseaccess-logo class="delivery_form__head--logo"/>
    </section>

    <div class="delivery_form__validation_section" v-if="!show_take_photo">
      <transition name="fade">
        <section class="delivery_form__content" v-if="!first_validation">
          <div class="delivery_form__title">
            ¿Donde vas a entregar?
          </div>
          <div class="delivery_form__address_row">
            <p class="delivery_form__address_row--name">{{company.name}}</p>
            <div class="delivery_form__address_row--address">
              <location-icon/>
              <p> {{company.address}}</p>
            </div>
          </div>
          <div class="delivery_form__fields">
            <div v-show="show_location" class="delivery_form__fields--select">
              <select
                name=""
                v-model="parcel_location_id"
                :class="{'disabled_text' : parcel_location_id == null}">
                <option value="null" disabled selected>Unidad*</option>
                <option
                  :value="location.location_id"
                  v-for="(location, index) in locations"
                  :key="location.location_id">{{location.location_name}}</option>
              </select>
            </div>
            <div v-if="parcel.location_id && show_location == true">
              <div class="delivery_form__fields--select">
                <select
                  name=""
                  v-model="parcel_sublocation_id"
                  :class="{'disabled_text' : parcel_sublocation_id == null}">
                  <option value="null" disabled selected>Apartamento*</option>
                  <option
                    :value="sublocation.sublocation_id"
                    v-for="(sublocation, index) in locations.find(element => element.location_id == parcel.location_id).sublocations"
                    :key="sublocation.sublocation_id">{{sublocation.sublocation_name}}</option>
                </select>
              </div>
              <div class="delivery_form__fields--button ">
                <button
                  class="delivery_form__button delivery_form__button--principal"
                  :class="{'delivery_form__button--disabled': formIsInError == true}"
                  @click="formIsInError == true ? '' : openFirstConfirmationModal()">
                  Continuar <arrowIcon class="delivery_form__button--arrow" :color="'#fff'"/>
                </button>
              </div>
            </div>
          </div>
        </section>
      </transition>
      <transition name="fade">
        <section class="delivery_modal" v-if="show_first_confirmation_modal">
          <div class="delivery_modal__content">
            <section class="delivery_modal__head">
              <div class="delivery_modal__head--icon">
                <warning-icon />
              </div>
              <p class="delivery_modal__head--text">¿La información es correcta?</p>
            </section>
            <section class="delivery_modal__card">
              <p class="delivery_modal__card--title">{{company.name}}</p>
              <div class="delivery_resume__address delivery_modal__card--text">
                <p><strong>Dirección:</strong> {{company.address}}</p>
              </div>
              <div class="delivery_resume__address delivery_modal__card--text">
                <p><strong>Unidad:</strong> {{findNameLocation()}}</p>
              </div>
              <div class="delivery_resume__address delivery_modal__card--text">
                <p><strong>Apartamento:</strong> {{findNameSublocation()}}</p>
              </div>
            </section>
            <section class="delivery_modal__buttons delivery_form__fields--button">
              <button
                class="delivery_form__button delivery_form__button--principal"
                @click="confirmSecondInformation()">
                Continuar <arrowIcon class="delivery_form__button--arrow" :color="'#fff'"/>
              </button>
              <div class="delivery_form__button--alone warning">
                <p @click="fixInformation()">No, voy a corregir</p>
              </div>
            </section>
          </div>
        </section>
      </transition>
      <section
        class="delivery_resume"
        v-if="show_second_confirmation_modal">
        <transition name="fade">
          <section class="delivery_resume__content">
            <div
              class="delivery_resume__content--close"
              @click="openFirstConfirmationModal()">
              <close-icon/>
            </div>
            <p class="delivery_resume__title">{{company.name}}</p>
            <div class="delivery_resume__address">
              <p>Dirección:</p>
              <p><strong>{{company.address}}</strong></p>
            </div>
            <div class="delivery_resume__address">
              <p>Unidad:</p>
              <p><strong>{{findNameLocation()}}</strong></p>
            </div>
            <div class="delivery_resume__address">
              <p>Apartamento:</p>
              <p><strong>{{findNameSublocation()}}</strong></p>
            </div>
            <section class="delivery_form__fields--button">
              <button
                class="delivery_form__button delivery_form__button--success"
                @click="clearLoading(); createParcel()">
                Abrir Locker <locked-icon :color="'#fff'"/>
              </button>
              <div class="delivery_form__button--alone default">
                <p @click="openFirstConfirmationModal()">Regresar</p>
              </div>
            </section>
          </section>
        </transition>
      </section>
    </div>

    <div class="delivery_form__taking_photo" v-else>
      <section class="delivery_photo__tutorial" v-if="show_tutorial">
        <div class="delivery_photo__tutorial--icon">
          <unlocked-icon/>
        </div>
        <p class="delivery_form__title delivery_photo__tutorial--title">¡El locker <span>{{available_locker_name}}</span> ha sido habilitado!</p>
        <div class="delivery_photo__tutorial--list">
          <div class="delivery_resume__address">
            <p><strong>1. Dirígete a el Locker {{available_locker_name}}</strong></p>
            <p><strong>2. Ingresa el paquete</strong></p>
            <p><strong>3. Tómate una fotografía</strong></p>
          </div>
        </div>
        <section class="delivery_form__fields--button delivery_photo__tutorial--buttons">
          <button
            class="delivery_form__button delivery_form__button--principal"
            @click="show_tutorial = false">
            Entendido
          </button>
        </section>
      </section>
      <section class="delivery_photo__take_photo" v-if="parcel_url_image === null && !show_tutorial">
        <div class="delivery_resume__address delivery_photo__take_photo--title">
          <p>Toma una foto del paquete</p>
        </div>
        <div class="delivery_photo__take_photo--card">
          <label
            for="selfie_uploader">
            <div class="delivery_photo__take_photo--camera_container">
              <div class="delivery_photo__take_photo--icon">
                <camera-icon/>
              </div>
            </div>
            <div class="delivery_photo__take_photo--button">
              Click aquí
            </div>
            <input
                id="selfie_uploader"
                type="file"
                name="user_photo"
                accept="image/*"
                capture="user"
                style="display:none"
                @change="uploadParcelPhoto($event)"/>
          </label>
        </div>
      </section>
      <section class="delivery_photo__confirm_photo" v-if="parcel_url_image != null && !show_tutorial">
        <div class="delivery_photo__confirm_photo--photo_container">
          <success-icon/>
          <div
            class="delivery_photo__confirm_photo--img"
            :style="{background: 'url('+parcel_url_image+')'}">
          </div>
          <div class="delivery_resume__address delivery_photo__take_photo--title delivery_photo__confirm_photo--text">
            <p>Foto cargada correctamente</p>
          </div>
        </div>
        <section class="delivery_form__fields--button delivery_photo__tutorial--buttons">
          <button
            class="delivery_form__button delivery_form__button--principal"
            @click="clearLoading(); sendNotification()">
            Finalizar
          </button>
        </section>
      </section>
    </div>
    <section class="delivery_photo__finish_modal" v-if="finish_modal">
      <section class="delivery_photo__finish_modal--content">
        <success-icon class="delivery_photo__finish_modal--icon"/>
        <div class="delivery_resume__address delivery_photo__finish_modal--text">
          <p><strong>¡Proceso realizado<br>correctamente!</strong></p>
        </div>
        <div class="delivery_resume__address delivery_photo__finish_modal--text">
          <p>Se ha <strong>notificado al usuario,</strong> gracias</p>
        </div>
        <section class="delivery_form__fields--button">
          <button
            class="delivery_form__button delivery_form__button--principal"
            @click="finishProcess(); finish_modal = false;">
            Ok
          </button>
        </section>
      </section>
    </section>
    <transition name="fade">
      <section v-if="first_validation && !second_validation">
        <h3>Por favor confirma de nuevo para dónde va la entrega</h3>
        <div v-show="show_location">
          <label>Unidad *</label>
          <select class="" name="" v-model="confirmation_location_id">
            <option value="" disabled selected>Selecciona la unidad en la que deseas entregar</option>
            <option
              :value="location.location_id"
              v-for="(location, index) in locations"
              :key="location.location_id">{{location.location_name}}</option>
          </select>
        </div>
        <div v-if="confirmation.location_id && show_location == true">
          <label>Apartamento *</label>
          <select class="" name="" v-model="confirmation_sublocation_id">
            <option value="" disabled selected>Selecciona el apartamento</option>
            <option
              :value="sublocation.sublocation_id"
              v-for="(sublocation, index) in locations.find(element => element.location_id == confirmation.location_id).sublocations"
              :key="sublocation.sublocation_id">{{sublocation.sublocation_name}}</option>
          </select>
        </div>
      </section>
    </transition>
    <transition name="fade">
      <section v-if="first_validation && second_validation">
        <label class="delivery_form--label" v-if="parcel_url_image != null">Foto del paquete</label>
        <img :src="parcel_url_image" alt="guest image" v-if="parcel_url_image != null">
        <label
          for="selfie_uploader"
          class="delivery_form--photo_btn"
          v-if="parcel_url_image === null">
          Tomar foto del paquete
          <input
              id="selfie_uploader"
              type="file"
              name="user_photo"
              accept="image/*;capture=camera"
              style="display:none"
              @change="uploadParcelPhoto($event)"/>
        </label>
      </section>
    </transition>
  </article>
</template>
<script>
import { months } from 'moment'
import Loader from '@/components/shared/Loader.vue'
import AlertModal from '@/components/shared/AlertModal.vue'
import VirtualGuardLogo from '@/components/shared/VirtualGuardLogo.vue'
import SpinLoader from '/src/components/shared/SpinLoader.vue'
import PercentageLoader from '/src/components/shared/PercentageLoader.vue'
import FuseaccessLogo from '/src/components/shared/icons/FuseaccessLogo.vue'
import LocationIcon from '/src/components/shared/icons/LocationIcon.vue'
import ArrowIcon from '/src/components/shared/icons/ArrowIcon.vue'
import WarningIcon from '/src/components/shared/icons/WarningIcon.vue'
import CloseIcon from '/src/components/shared/icons/CloseIcon.vue'
import LockedIcon from '/src/components/shared/icons/LockedIcon.vue'
import UnlockedIcon from '/src/components/shared/icons/UnlockedIcon.vue'
import CameraIcon from '/src/components/shared/icons/CameraIcon.vue'
import SuccessIcon from '/src/components/shared/icons/SuccessIcon.vue'
export default {
  data(){
    return{
      show_errors: false,
      show_alert: false,
      alert_message: '',
      loader: false,
      parcel: {
        parcel_id: null,
        file: null,
        locker_location_id: null,
        location_id: null,
        sublocation_id: null
      },
      confirmation: {
        location_id: null,
        sublocation_id: null
      },
      fieldError:'',
      current_company: null,
      locations: [],
      current_location: null,
      current_sublocation: null,
      active_sublocations: false,
      show_location: true,
      parcel_url_image: null,
      completed_validation: false,
      show_first_confirmation_modal: false,
      show_second_confirmation_modal: false,
      first_validation: false,
      second_validation: false,
      uploaded_photo: false,
      loading_percentage: 0,
      confirmation_location_id: null,
      confirmation_sublocation_id: null,
      parcel_location_id: null,
      parcel_sublocation_id: null,
      finish_modal:false,
      show_take_photo: false,
      available_locker_name: '',
      show_tutorial: false,
      company:{
        id:null,
        name: '',
        address: ''
      }
    }
  },
  mounted() {
    this.current_company = this.$route.query.id ? this.$route.query.id : null
    if(this.$route.query.location_id && this.$route.query.location_id){
      this.show_location = false
    }
    if(this.$route.query.locker_location_id && this.$route.query.locker_location_id != ''){
      this.parcel.locker_location_id = parseInt(this.$route.query.locker_location_id)
    }
    if(this.current_company != null && (this.parcel.location_id == null || this.parcel.sublocation_id == null)){
      this.findLocationsByCompany()
    }
  },
  watch:{
    parcel_location_id(){
      this.parcel.location_id = this.parcel_location_id
      this.parcel_sublocation_id = null
      console.log(this.parcel.location_id);
    },
    parcel_sublocation_id(){
      this.parcel.sublocation_id = this.parcel_sublocation_id
      console.log(this.parcel.sublocation_id);
    },
    confirmation_location_id(){
      this.confirmation.location_id = this.confirmation_location_id
      this.confirmation_sublocation_id = null
    },
    confirmation_sublocation_id(){
      this.confirmation.sublocation_id = this.confirmation_sublocation_id
    }
  },
  components:{
    'loader': Loader,
    'virtual-guard-logo': VirtualGuardLogo,
    'alert-modal': AlertModal,
    PercentageLoader,
    FuseaccessLogo,
    SpinLoader,
    LocationIcon,
    ArrowIcon,
    WarningIcon,
    CloseIcon,
    LockedIcon,
    UnlockedIcon,
    CameraIcon,
    SuccessIcon
  },
  computed:{
    formIsInError(){
      var ans=false
      let fields=[]
      if(this.parcel.location_id == null){
        fields.push("'Selecciona la torre o edificio'")
      }
      if(this.parcel.sublocation_id == null){
        fields.push("'Selecciona el apartamento u oficina'")
      }
      if(fields.length>0){
        if(fields.length==2){
          this.fieldError=fields.join(" y ")
        }else{
          this.fieldError=fields.join(" , ")
        }
        this.$validator.validateAll()
        ans=true
      }else{
        this.fieldError=""
      }
      return ans
    },
    confirmationFormIsInError(){
      var ans=false
      let fields=[]
      if(!this.confirmation.location_id){
        fields.push("'Selecciona la torre o edificio'")
      }
      if(!this.confirmation.sublocation_id){
        fields.push("'Selecciona el apartamento u oficina'")
      }
      console.log('fields', fields)
      if(fields.length>0){
        if(fields.length==2){
        this.fieldError=fields.join(" y ")
        }else{
        this.fieldError=fields.join(" , ")
        }
        this.$validator.validateAll()
        ans=true
      }else{
        this.fieldError=""
      }
      return ans
    },
    scheduleIsInError(){
      var ans=false
      if(this.errorOpening||this.errorEnding||this.spacesInError){
        ans=true
      }
      return ans
    }
  },
  methods:{
    // Percentage Loader Fuinctions
    loadPercentage(number){
      for (var i = 0; i < number; i++) {
        this.sumPercentage()
      }
    },
    sumPercentage(){
      if(this.loading_percentage < 100){
        this.loading_percentage += 10
      }
    },
    createParcel(){
      var vm = this
      vm.show_second_confirmation_modal = false
      vm.loader = true
      setTimeout(function(){ vm.createLockerParcel() }, 1000)
    },
    sendNotification(){
      var vm = this
      vm.loader = true
      setTimeout(function(){ vm.sendDeliveryNotification() }, 1000)
    },
    clearLoading(){
      this.loading_percentage = 0;
      this.loadPercentage(0);
    },
    findLocationsByCompany(){
      this.clearLoading()
      this.loader = true
      this.loadPercentage(8);
      try {
        this.$http.get('app/company/find_locations_by_company/'+this.current_company)
        .then(function(response){
          console.log(">>>>>>>>>>>>>>>>>>>>>>>Success");
          console.log(response);
          this.loadPercentage(2);
          this.locations = response.body.ans
          this.company = response.body.company_info
          console.log('locations', this.locations)
          this.loader = false
        }, function(response){
          this.loadPercentage(2);
          console.log("<<<<<<<<<<<<<<<<<<<<<Fail");
          console.log(response.body.data)
          this.loader = false
        })
      } catch (e) {
        console.log(e.message)
        this.loadPercentage(2);
        this.loader = false
      }
    },
    finishProcess(){
      this.finish_modal = true;
      this.show_take_photo = false;
      this.fixInformation()
      this.clearFields()
    },
    clearFields(){
      this.parcel = {
        locker_location_id: '',
        location_id: '',
        sublocation_id: '',
      }
      this.parcel.locker_location_id = this.$route.query.locker_location_id ? Number(this.$route.query.locker_location_id) : null
      if(this.current_company != null){
        this.findLocationsByCompany()
      }
    },
    createLockerParcel(){
      console.log("<<<<<<<<<<<<<<<<<<<<<< Send Notification");
      console.log(this.parcel);
      this.clearLoading()
      this.loader = true
      this.loadPercentage(8);
      try {
        this.$http.post('parcels/create_locker_parcel', {data: this.encrypt(this.parcel).toString()})
        .then(function(response){
          console.log(response);
          // this.alert_message = response.body.ans
          // this.show_alert = true
          this.second_validation = true
          this.parcel.parcel_id = response.body.parcel_id
          this.available_locker_name = response.body.available_locker_name
          this.loadPercentage(2);
          this.loader = false
          this.show_take_photo = true
          this.show_tutorial = true
        }, function(response){
          console.log(response);
          if(response.body.ans !== undefined){
            this.alert_message = response.body.ans
            this.show_alert = true
          }else if(typeof response.body.errors[0].details != "object"){
            this.alert_message = response.body.errors[0].details
            this.show_alert = true
          }else{
            let message = []
            for(var key in response.body.errors[0].details){
              message.push(response.body.errors[0].details[key])
            }
            this.alert_message = message
            this.show_alert = true
          }
          this.loadPercentage(2);
          this.loader = false
        })
      } catch (e) {
        console.log(e);
        this.loadPercentage(2);
        this.loader = false
        this.alert_message = e.message
        this.show_alert = true
      }
    },
    sendDeliveryNotification(){
      console.log("<<<<<<<<<<<<<<<<<<<<<< Send Notification");
      console.log(this.parcel);
      this.clearLoading()
      this.loader = true
      this.loadPercentage(8);
      try {
        this.$http.post('parcels/notify_locker_parcel', {data: this.encrypt(this.parcel).toString()})
        .then(function(response){
          // this.alert_message = response.body.ans
          // this.show_alert = true
          this.confirmation = { location_id: null, sublocation_id: null }
          this.parcel = { parcel_id: null , file: null, locker_location_id: parseInt(this.$route.query.locker_location_id), location_id: null, sublocation_id: null }
          console.log(">-----------------------------Clear Parcel", this.parcel);
          this.parcel_location_id = null
          this.confirmation_location_id = null
          this.parcel.locker_location_id = this.$route.query.locker_location_id ? Number(this.$route.query.locker_location_id) : null
          this.loadPercentage(2);
          this.parcel_url_image = null
          this.first_validation = false
          this.second_validation = false
          this.loader = false
          this.finishProcess()
        }, function(response){
          if(response.body.ans !== undefined){
            this.alert_message = response.body.ans
            this.show_alert = true
          }else if(typeof response.body.errors[0].details != "object"){
            this.alert_message = response.body.errors[0].details
            this.show_alert = true
          }else{
            let message = []
            for(var key in response.body.errors[0].details){
              message.push(response.body.errors[0].details[key])
            }
            this.alert_message = message
            this.show_alert = true
          }
          this.loadPercentage(2);
          this.loader = false
        })
      } catch (e) {
        this.loadPercentage(2);
        this.loader = false
        this.alert_message = e.message
        this.show_alert = true
      }
    },
    validateForms(){
      if (this.parcel.location_id === this.confirmation.location_id && this.parcel.sublocation_id === this.confirmation.sublocation_id){
        this.show_second_confirmation_modal = true
        // this.sendDeliveryInfo()
      }else{
        this.alert_message = 'La selección actual no coincide con la del paso anterior'
        this.show_alert = true
      }
    },
    closeAlertModal(){
      this.alert_message = ''
      this.show_alert = false
    },
    async uploadParcelPhoto(event){
      console.log(event);

      let vm = this
      const parcel_img = event.target.files[0]
      const extension = event.target.files[0].type.split('/')[1]

      console.log("PESO ORIGINAL DE LA FOTO " + parcel_img.size)
      console.log("EXTENSION " + extension)

      if(extension != "jpg" && extension != "jpeg"){
        alert('Solo se admite el formato jpg o jpeg')

      } else {
        vm.isLoading = true
        setTimeout(() => vm.isLoading = false, 1000);

        document.querySelector('.delivery_form').scrollIntoView();

        const blob = await this.compressImage(parcel_img, parseInt(10));
        vm.parcel_url_image = window.URL.createObjectURL(parcel_img);
        console.log("PESO DE LA FOTO COMPRIMIDA " + blob.size)
        vm.loadUserImage(blob)
      }
    },
    compressImage(imageFile, quality){
      return new Promise((resolve, reject) => {
        const $canvas = document.createElement("canvas")
        const imagen = new Image();
        var oldWidth , oldHeight , newHeight , newWidth = 1000;

        imagen.onload = () => {
          oldWidth = imagen.width;
          oldHeight = imagen.height;
          newHeight = Math.floor(oldHeight / oldWidth * newWidth)
          $canvas.width = newWidth;
          $canvas.height = newHeight;
          $canvas.getContext("2d").drawImage(imagen, 0, 0, newWidth,newHeight);
          $canvas.toBlob(
            (blob) => {
              if (blob === null) {
                return reject(blob);
              } else {
                resolve(blob);
              }
            },
            "image/jpeg",
            quality / 100
          );
        };
        imagen.src = URL.createObjectURL(imageFile);
      });
    },
    loadUserImage(file){
      let reader = new FileReader()
      const vm = this
      reader.readAsDataURL(file);
      reader.onload = () => {
        vm.parcel.file = reader.result
      }
      vm.uploaded_photo = true
    },
    openFirstConfirmationModal(){
      this.show_errors = true
      this.show_first_confirmation_modal = true
      this.show_second_confirmation_modal = false
    },
    fixInformation(){
      this.show_errors = false
      this.show_first_confirmation_modal = false
    },
    findNameLocation(){
      let vm = this
      console.log(vm.locations);
      console.log(vm.locations.filter((location) => location.location_id == vm.parcel.location_id));
      let filter_name = vm.locations.filter((location) => location.location_id == vm.parcel.location_id)[0].location_name
      return filter_name
    },
    findNameSublocation(){
      let vm = this
      let filter_name = vm.locations.filter((location) => location.location_id == vm.parcel.location_id)[0].sublocations.filter((sublocation) => sublocation.sublocation_id ==vm.parcel.sublocation_id)[0].sublocation_name
      return filter_name
    },
    confirmSecondInformation(){
      this.fixInformation()
      this.show_second_confirmation_modal = true
    }
  }
}
</script>
