<template>
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  	 viewBox="0 0 500 177.7" style="enable-background:new 0 0 500 177.7;" xml:space="preserve">
    <g>
    	<path class="st0" d="M414.05,168.49H85.94c-43.71,0-79.14-35.43-79.14-79.14v0c0-43.71,35.43-79.14,79.14-79.14h328.11
    		c43.71,0,79.14,35.43,79.14,79.14v0C493.19,133.06,457.76,168.49,414.05,168.49z"/>
    	<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="249.9941" y1="168.4897" x2="249.9941" y2="10.2085">
    		<stop  offset="0.1965" style="stop-color:#0F4495"/>
    		<stop  offset="0.2713" style="stop-color:#144595;stop-opacity:0.9069"/>
    		<stop  offset="0.4721" style="stop-color:#1B4795;stop-opacity:0.657"/>
    		<stop  offset="1" style="stop-color:#1D4795;stop-opacity:0"/>
    	</linearGradient>
    	<path class="st1" d="M414.05,168.49H85.94c-43.71,0-79.14-35.43-79.14-79.14v0c0-43.71,35.43-79.14,79.14-79.14h328.11
    		c43.71,0,79.14,35.43,79.14,79.14v0C493.19,133.06,457.76,168.49,414.05,168.49z"/>
    </g>
    <g>
    	<g>
    		<path class="st2" d="M224.05,81.42l-12.01-27.47h5.53l10.71,24.84h-3.18l10.83-24.84h5.1l-11.97,27.47H224.05z"/>
    		<path class="st2" d="M246.15,57.01c-0.92,0-1.67-0.29-2.26-0.86c-0.59-0.58-0.88-1.27-0.88-2.08c0-0.84,0.29-1.54,0.88-2.1
    			c0.59-0.56,1.34-0.84,2.26-0.84c0.92,0,1.67,0.27,2.26,0.8c0.59,0.54,0.88,1.21,0.88,2.02c0,0.86-0.29,1.59-0.86,2.18
    			C247.85,56.72,247.09,57.01,246.15,57.01z M243.68,81.42V60.47h4.91v20.96H243.68z"/>
    		<path class="st2" d="M263.89,80.48c-1.99,0-3.79-0.42-5.4-1.28c-1.61-0.85-2.88-2.03-3.81-3.55c-0.93-1.52-1.39-3.3-1.39-5.34
    			c0-2.01,0.46-3.78,1.39-5.3c0.93-1.52,2.2-2.69,3.81-3.53c1.61-0.84,3.41-1.26,5.4-1.26c1.78,0,3.38,0.35,4.79,1.06
    			c1.41,0.71,2.54,1.81,3.39,3.3c0.85,1.49,1.28,3.4,1.28,5.73c0,2.33-0.42,4.25-1.28,5.75c-0.85,1.5-1.98,2.62-3.39,3.34
    			C267.26,80.12,265.67,80.48,263.89,80.48z M264.51,89.31c-1.94,0-3.83-0.25-5.67-0.77c-1.84-0.51-3.35-1.25-4.53-2.22l2.2-3.69
    			c0.92,0.76,2.07,1.37,3.47,1.82c1.4,0.46,2.82,0.69,4.26,0.69c2.3,0,3.99-0.53,5.06-1.59c1.07-1.06,1.61-2.65,1.61-4.77v-3.73
    			l0.39-4.75l-0.16-4.75v-5.1h4.67v17.78c0,3.79-0.97,6.59-2.9,8.38S268.18,89.31,264.51,89.31z M264.63,76.32
    			c1.23,0,2.33-0.26,3.3-0.77c0.97-0.51,1.72-1.22,2.26-2.12c0.54-0.9,0.8-1.94,0.8-3.12c0-1.18-0.27-2.22-0.8-3.12
    			c-0.54-0.9-1.29-1.6-2.26-2.08c-0.97-0.48-2.07-0.73-3.3-0.73c-1.23,0-2.33,0.24-3.32,0.73c-0.98,0.48-1.74,1.18-2.28,2.08
    			c-0.54,0.9-0.8,1.94-0.8,3.12c0,1.18,0.27,2.22,0.8,3.12c0.54,0.9,1.3,1.61,2.28,2.12C262.3,76.07,263.4,76.32,264.63,76.32z"/>
    		<path class="st2" d="M284.72,57.01c-0.92,0-1.67-0.29-2.26-0.86c-0.59-0.58-0.88-1.27-0.88-2.08c0-0.84,0.29-1.54,0.88-2.1
    			c0.59-0.56,1.34-0.84,2.26-0.84s1.67,0.27,2.26,0.8c0.59,0.54,0.88,1.21,0.88,2.02c0,0.86-0.29,1.59-0.86,2.18
    			C286.42,56.72,285.67,57.01,284.72,57.01z M282.25,81.42V60.47h4.91v20.96H282.25z"/>
    		<path class="st2" d="M293.59,81.42V52.3h4.91v29.12H293.59z"/>
    		<path class="st2" d="M311.25,81.7c-1.57,0-2.94-0.27-4.12-0.8c-1.18-0.54-2.09-1.28-2.73-2.24c-0.64-0.96-0.96-2.03-0.96-3.24
    			c0-1.18,0.28-2.24,0.84-3.18s1.48-1.69,2.77-2.24c1.28-0.55,2.98-0.82,5.1-0.82h6.08v3.26h-5.73c-1.67,0-2.8,0.27-3.38,0.8
    			c-0.58,0.54-0.86,1.2-0.86,1.98c0,0.89,0.35,1.6,1.06,2.12c0.71,0.52,1.69,0.79,2.94,0.79c1.2,0,2.28-0.27,3.24-0.82
    			c0.95-0.55,1.64-1.36,2.06-2.43l0.82,2.94c-0.47,1.23-1.31,2.18-2.53,2.86C314.65,81.36,313.11,81.7,311.25,81.7z M317.85,81.42
    			v-4.24l-0.28-0.9v-7.42c0-1.44-0.43-2.56-1.29-3.36c-0.86-0.8-2.17-1.2-3.92-1.2c-1.18,0-2.34,0.18-3.47,0.55
    			c-1.14,0.37-2.1,0.88-2.88,1.53l-1.92-3.57c1.12-0.86,2.47-1.51,4.02-1.94s3.17-0.65,4.85-0.65c3.04,0,5.38,0.73,7.04,2.18
    			c1.66,1.45,2.49,3.7,2.49,6.73v12.28H317.85z"/>
    		<path class="st2" d="M340.65,60.23c1.67,0,3.17,0.33,4.49,0.98c1.32,0.65,2.36,1.65,3.12,3c0.76,1.35,1.14,3.08,1.14,5.2v12.01
    			h-4.91V70.04c0-1.86-0.44-3.24-1.31-4.16c-0.88-0.92-2.1-1.37-3.67-1.37c-1.15,0-2.17,0.24-3.06,0.71
    			c-0.89,0.47-1.58,1.17-2.06,2.1c-0.48,0.93-0.73,2.1-0.73,3.51v10.6h-4.91V60.47h4.67v5.65l-0.82-1.73
    			c0.73-1.33,1.8-2.36,3.2-3.08C337.2,60.59,338.81,60.23,340.65,60.23z"/>
    		<path class="st2" d="M352.85,64.55v-3.92h13.97v3.92H352.85z M363.53,81.7c-2.3,0-4.08-0.6-5.34-1.79s-1.88-2.95-1.88-5.28v-18.8
    			h4.91v18.68c0,0.99,0.25,1.77,0.76,2.32c0.51,0.55,1.22,0.82,2.14,0.82c1.1,0,2.01-0.29,2.75-0.86l1.37,3.49
    			c-0.58,0.47-1.28,0.82-2.12,1.06C365.28,81.58,364.42,81.7,363.53,81.7z"/>
    		<path class="st2" d="M381.42,81.7c-2.33,0-4.36-0.46-6.1-1.39c-1.74-0.93-3.09-2.2-4.04-3.83c-0.96-1.62-1.43-3.47-1.43-5.53
    			c0-2.09,0.46-3.94,1.39-5.55c0.93-1.61,2.21-2.87,3.85-3.79c1.63-0.92,3.5-1.37,5.59-1.37c2.04,0,3.86,0.44,5.45,1.33
    			c1.6,0.89,2.85,2.15,3.77,3.77c0.92,1.62,1.37,3.53,1.37,5.73c0,0.21-0.01,0.44-0.02,0.71c-0.01,0.26-0.03,0.51-0.06,0.75h-17.46
    			v-3.26h14.87l-1.92,1.02c0.03-1.2-0.22-2.26-0.75-3.18c-0.52-0.92-1.24-1.63-2.14-2.16c-0.9-0.52-1.94-0.78-3.12-0.78
    			c-1.2,0-2.26,0.26-3.16,0.78c-0.9,0.52-1.61,1.25-2.12,2.18c-0.51,0.93-0.76,2.02-0.76,3.28v0.79c0,1.26,0.29,2.37,0.86,3.34
    			c0.58,0.97,1.39,1.71,2.43,2.24c1.05,0.52,2.25,0.78,3.61,0.78c1.18,0,2.24-0.18,3.18-0.55c0.94-0.37,1.78-0.94,2.51-1.73
    			l2.63,3.02c-0.94,1.1-2.13,1.94-3.55,2.53C384.88,81.4,383.25,81.7,381.42,81.7z"/>
    		<path class="st2" d="M246.48,123.68l-8.99-20.96h5.1l7.73,18.48h-2.51l8.01-18.48h4.71l-8.99,20.96H246.48z"/>
    		<path class="st2" d="M266.06,99.27c-0.92,0-1.67-0.29-2.26-0.86c-0.59-0.58-0.88-1.27-0.88-2.08c0-0.84,0.29-1.54,0.88-2.1
    			c0.59-0.56,1.34-0.84,2.26-0.84s1.67,0.27,2.26,0.8s0.88,1.21,0.88,2.02c0,0.86-0.29,1.59-0.86,2.18
    			C267.76,98.97,267.01,99.27,266.06,99.27z M263.59,123.68v-20.96h4.91v20.96H263.59z"/>
    		<path class="st2" d="M274.93,123.68v-20.96h4.67v5.77l-0.55-1.69c0.63-1.41,1.62-2.49,2.96-3.22c1.35-0.73,3.03-1.1,5.04-1.1v4.67
    			c-0.21-0.05-0.41-0.08-0.59-0.1c-0.18-0.01-0.37-0.02-0.55-0.02c-1.86,0-3.33,0.54-4.43,1.63c-1.1,1.09-1.65,2.7-1.65,4.85v10.16
    			H274.93z"/>
    		<path class="st2" d="M289.22,106.8v-3.92h13.97v3.92H289.22z M299.89,123.95c-2.3,0-4.08-0.59-5.34-1.79s-1.88-2.95-1.88-5.28
    			v-18.8h4.91v18.68c0,1,0.25,1.77,0.76,2.32c0.51,0.55,1.22,0.82,2.14,0.82c1.1,0,2.01-0.29,2.75-0.86l1.37,3.49
    			c-0.58,0.47-1.28,0.82-2.12,1.06C301.64,123.83,300.78,123.95,299.89,123.95z"/>
    		<path class="st2" d="M317.51,123.95c-1.78,0-3.35-0.33-4.71-1c-1.36-0.67-2.42-1.69-3.18-3.06s-1.14-3.11-1.14-5.2v-11.97h4.91
    			v11.3c0,1.88,0.42,3.29,1.28,4.22c0.85,0.93,2.06,1.39,3.63,1.39c1.15,0,2.15-0.24,3-0.71c0.85-0.47,1.52-1.18,2-2.12
    			c0.48-0.94,0.73-2.11,0.73-3.49v-10.6h4.91v20.96h-4.67v-5.65l0.82,1.73c-0.71,1.36-1.74,2.4-3.1,3.12
    			C320.62,123.59,319.13,123.95,317.51,123.95z"/>
    		<path class="st2" d="M341.69,123.95c-1.57,0-2.94-0.27-4.12-0.8c-1.18-0.54-2.09-1.28-2.73-2.24c-0.64-0.96-0.96-2.03-0.96-3.24
    			c0-1.18,0.28-2.24,0.84-3.18c0.56-0.94,1.48-1.69,2.77-2.24c1.28-0.55,2.98-0.82,5.1-0.82h6.08v3.26h-5.73
    			c-1.67,0-2.8,0.27-3.38,0.8c-0.58,0.54-0.86,1.2-0.86,1.98c0,0.89,0.35,1.6,1.06,2.12c0.71,0.52,1.69,0.78,2.94,0.78
    			c1.2,0,2.28-0.27,3.24-0.82c0.95-0.55,1.64-1.36,2.06-2.43l0.82,2.94c-0.47,1.23-1.31,2.18-2.53,2.86
    			C345.08,123.61,343.54,123.95,341.69,123.95z M348.28,123.68v-4.24l-0.28-0.9v-7.42c0-1.44-0.43-2.56-1.29-3.36
    			c-0.86-0.8-2.17-1.2-3.92-1.2c-1.18,0-2.34,0.18-3.47,0.55c-1.14,0.37-2.1,0.88-2.88,1.53l-1.92-3.57
    			c1.12-0.86,2.47-1.51,4.02-1.94s3.17-0.65,4.85-0.65c3.04,0,5.38,0.73,7.04,2.18c1.66,1.45,2.49,3.7,2.49,6.73v12.28H348.28z"/>
    		<path class="st2" d="M359.19,123.68V94.56h4.91v29.12H359.19z"/>
    	</g>
    </g>
    <circle class="st2" cx="79.4" cy="89.67" r="61.46"/>
    <circle class="st3" cx="79.4" cy="89.67" r="59.16"/>
    <linearGradient id="SVGID_00000165200412811250743670000005735182897819972752_" gradientUnits="userSpaceOnUse" x1="79.397" y1="152.1814" x2="79.397" y2="33.8649">
    	<stop  offset="0" style="stop-color:#616160"/>
    	<stop  offset="0.1451" style="stop-color:#616160;stop-opacity:0.8549"/>
    	<stop  offset="1" style="stop-color:#616160;stop-opacity:0"/>
    </linearGradient>
    <circle style="opacity:0.15;fill:url(#SVGID_00000165200412811250743670000005735182897819972752_);" cx="79.4" cy="89.67" r="59.16"/>
    <linearGradient id="SVGID_00000034075645324910778380000004424620299276934312_" gradientUnits="userSpaceOnUse" x1="79.397" y1="152.1814" x2="79.397" y2="33.8649">
    	<stop  offset="0" style="stop-color:#616160"/>
    	<stop  offset="0.1451" style="stop-color:#616160;stop-opacity:0.8549"/>
    	<stop  offset="1" style="stop-color:#616160;stop-opacity:0"/>
    </linearGradient>
    <circle style="opacity:0.15;fill:url(#SVGID_00000034075645324910778380000004424620299276934312_);" cx="79.4" cy="89.67" r="59.16"/>
    <g>
    	<path class="st6" d="M79.43,132.63c-34.14-2.78-28.65-37.06-28.65-37.06l-0.15-5.52c-5.13,0-5.13-10.18,0-10.18l-3.85-21.29
    		c20.22,1.39,32.44-8.64,32.44-8.64l0,0c0,0,12.58,10.03,32.79,8.64l-3.85,21.29c5.16,0,5.16,10.18,0,10.18L108,95.57
    		c0,0,5.48,34.29-28.65,37.06H79.43z"/>
    	<line class="st6" x1="108.15" y1="79.87" x2="50.62" y2="79.87"/>
    	<line class="st6" x1="108.15" y1="90.05" x2="50.62" y2="90.05"/>
    	<path class="st6" d="M51.15,90.05c0,16.04,56.47,16.04,56.47,0"/>
    	<path class="st6" d="M79.34,73.7c6.78,0,8.8-10.65,8.8-10.65c-4.78,0-8.8-4.47-8.8-4.47h0.1c0,0-4.01,4.47-8.8,4.47
    		c0,0,2.01,10.65,8.8,10.65"/>
    </g>
  </svg>

</template>

<script>
export default {
}
</script>

<style lang="css">
.st0{fill:#0F4495;}
.st1{fill:url(#SVGID_1_);}
.st2{fill:#FFFFFF;}
.st3{opacity:0.15;fill:#616160;}
.st4{opacity:0.15;fill:url(#SVGID_00000183953798819772201530000016274566325985076901_);}
.st5{opacity:0.15;fill:url(#SVGID_00000007398086552423410410000004630539785417465779_);}
.st6{fill:none;stroke:#0F4495;stroke-width:3.4034;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st7{opacity:0.15;fill:url(#SVGID_00000163761445331817439560000013066392693747475080_);}
.st8{opacity:0.15;fill:url(#SVGID_00000160885601246393167330000003812872608870159547_);}
.st9{fill:none;stroke:#0F4495;stroke-width:4.5405;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
</style>
