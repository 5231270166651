<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 76 76">
    <defs>
      <clipPath id="clippath">
        <rect fill="none" x="-136.5" y="685.31" width="360" height="44.69"/>
      </clipPath>
    </defs>
    <g
      id="Capa_1"
      data-name="Capa 1">
      <g fill="none">
        <image
          width="540"
          height="1200"
          transform="translate(-136.5 -69.45) scale(.67)"
          xlink:href="G:\Mi unidad\Laura2023\Fuseaccess\App fuseaccess\Paqueteria\WhatsApp Image 2023-06-30 at 2.43.13 PM.jpeg"/>
      </g>
    </g>
    <g id="Capa_2" data-name="Capa 2">
      <path
        :fill="color"
        d="m55.32,75H20.68c-1.93-.33-3.81-.84-5.51-1.84-5.26-3.07-7.94-7.69-8.06-13.75-.08-4.16-.03-8.32-.01-12.48.01-2.28-.11-4.57.09-6.85.5-5.69,3.29-9.89,8.28-12.63.64-.35.89-.72.88-1.45-.04-2.26-.09-4.52.28-6.76C19.36,2.8,38.6-4.59,51.57,5.8c2.19,1.75,3.96,3.86,5.31,6.31.88,1.59.36,3.46-1.15,4.33-1.5.87-3.31.4-4.28-1.11-.13-.2-.25-.41-.38-.62-2.82-4.45-6.87-7.03-12.11-7.3-6.41-.33-11.29,2.46-14.47,8.06-1.34,2.35-1.83,4.93-1.79,7.63.04,2.52,0,2.52,2.49,2.52,9.19,0,18.38,0,27.57,0,.86,0,1.74.03,2.59.15,7.9,1.13,13.48,7.5,13.54,15.52.05,6.04.06,12.08,0,18.11-.06,5.43-2.31,9.8-6.76,12.96-2.04,1.45-4.35,2.22-6.8,2.63Zm-20.31-24.62c0,1.01-.01,2.02,0,3.03.03,1.72,1.37,3.08,3.01,3.05,1.64-.03,2.93-1.34,2.95-3.04.02-2.04.02-4.09,0-6.13-.02-1.69-1.33-2.98-2.97-2.99-1.64,0-2.95,1.29-2.99,2.97-.02,1.03,0,2.07,0,3.1Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  props:{
    color:{
      type: String,
      default: "#39b54a"
    }
  }
}
</script>

<style lang="css">
</style>
