<template>
  <article class="" id="app">
    <router-view></router-view>
  </article>
</template>

<script>
import Upload from '@/components/shared/Upload.vue'

  export default {
    name: 'App',
    data: () => ({

    }),
    props: {
      source: String
    },
    components: {
      'upload-icon': Upload
    },
    mounted(){
      var uuid
      if(localStorage.device_id != undefined &&
         localStorage.device_id != null &&
         localStorage.device_id != ''){
        uuid = localStorage.device_id
      } else {
        uuid = this.$uuid.v1()
        localStorage.device_id = uuid
      }
      console.log("-----------------------Loader");
      // this.$router.push({name: 'invitation'})
      // try{
      //   let vm = this;
      //   let params = this.$router.app._route.query
      //
      //   if(JSON.stringify(params)!=='{}' && (params.email!==undefined && params.email!=='') && (params.password!==undefined && params.password!=='') && (params.company_id!==undefined && params.company_id!=='')){
      //     this.updateIsCompleteRegistration(true);
      //     this.validateUserCredentials(params.email, params.password, params.company_id)
      //   }else if (JSON.stringify(params)!=='{}' &&
      //    //(params.user_id!==undefined && params.user_id!=='') &&
      //    (params.device_id!==undefined && params.device_id!=='') &&
      //    (params.invited_client_id!==undefined && params.invited_client_id!=='')) {
      //     this.updateIsQrCode(true);
      //   }
      // }catch(e){
      //   console.log('CodeQr not params ', e);
      // }
    }
  }
</script>

<style lang="scss">
@import "./assets/stylesheets/application.scss";
#keep main .container {
  height: 660px !important;
}
.general_contain {
  padding-right: 4.1% !important; //4.8
  padding-top: 100px !important;
  padding-left: 8.2% !important;
  //padding-left: 9.6%;
  @include breakpoint(big-desktop-up) {
    //padding-left: 12.5%;
    padding-left: 12.3% !important;
  }
}
.general_contain_collapse {
  padding-right: 4.1% !important; //4.8
  padding-top: 100px !important;
  padding-left: 4.1% !important;
  //padding-left: 9.6%;
  @include breakpoint(big-desktop-up) {
    //padding-left: 12.5%;
    padding-left: 4.1% !important;
  }
}
.v-navigation-drawer__border {
  display: none !important;
}
.text {
  font-weight: 400 !important;
}
.float_card {
  margin-left: 30px !important;
  width: 35% !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  max-height: 80vh !important;
}
.divider {
  margin: 40px auto !important;
}
.application {
  font-family: "Poppins" !important;
  color: #99a3b5 !important;
}
.primary--text {
  color: #1476fb !important;
}
</style>
