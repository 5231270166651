<template>
    <center>
        <article class="form_modal__fair access_requirement">
            <loader v-show="false"></loader>
            <section class="form_modal_fair__container_video fair_invitation fair_invitation_right">
            <section class="access_requirement__company_logo">
                <img src="https://fusepongsolutions.s3.us-west-2.amazonaws.com/Logofuseaccess.png" alt="">
            </section>
            <section class="access_requirement__virtual_guard">
                <!-- <img src="" alt=""> -->
                <virtual-guard-logo></virtual-guard-logo>
            </section>
            <div class="fair_invitation__content">
                <div class="fair_invitation__content--right" style="text-align:center">
                
                    <img src="https://www.freeiconspng.com/uploads/sign-red-error-icon-1.png" style="width:40%;margin-bottom:5%">
                <div>
                    <h3>Acceso denegado</h3>
                </div>



                </div>
            </div>
            </section>
        </article>
    </center>
</template>


<script>
import Loader from '@/components/shared/Loader.vue'
import VirtualGuardLogo from '@/components/shared/VirtualGuardLogo.vue'
   export default {

         components:{
            'loader': Loader,
            'virtual-guard-logo': VirtualGuardLogo
        },

   }
</script>
