<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76">
    <path
      :fill="color" d="m55.59,65.63H20.41c-.12-.03-.24-.06-.37-.08-2.99-.53-5.43-1.98-7.31-4.36-1.3-1.65-2.01-3.54-2.36-5.59,0-7.88,0-15.75,0-23.63.03-.12.05-.24.08-.37.99-5.7,5.66-9.75,11.46-9.78,10.73-.04,21.46-.04,32.19,0,3.54.01,6.52,1.41,8.85,4.12,1.5,1.74,2.32,3.78,2.68,6.03v23.63c-.03.14-.06.28-.09.42-.59,3.19-2.19,5.73-4.83,7.61-1.54,1.09-3.27,1.69-5.11,2.01Zm-17.61-9.3c6.94.12,13.79-5.46,13.76-13.79-.03-8.07-6.57-13.7-13.77-13.69-7.14.01-13.71,5.63-13.71,13.75,0,8.22,6.74,13.81,13.72,13.72Z"/>
    <path
      :fill="color"
      d="m43.61,10.37c2.13.45,3.74,1.65,4.97,3.43.72,1.03,1.53,2,2.3,2.99.09.12.26.25.18.42-.08.17-.29.1-.44.1-8.41,0-16.82,0-25.23,0-.15,0-.36.07-.44-.1-.09-.17.09-.3.18-.42,1.01-1.31,2.01-2.63,3.04-3.92,1.09-1.37,2.56-2.11,4.23-2.51h11.22Z"/>
    <path
      :fill="color"
      d="m37.97,51.85c-5.1,0-9.29-4.2-9.26-9.27.03-5.11,4.2-9.26,9.29-9.26,5.11,0,9.32,4.22,9.29,9.32-.03,5.1-4.19,9.21-9.32,9.21Z"/>
  </svg>
</template>

<script>
export default {
  props:{
    color:{
      type: String,
      default: "#1877cb"
    }
  }
}
</script>

<style lang="css">
</style>
